<template>
  <el-table-column
    :width="field.width"
    :sortable="field.sortable ? 'custom' : false"
    :label="field.label"
    :prop="field.prop"
    :key="field.prop"
  >
    <template slot-scope="scope" class="pull-right">
      <i
        v-if="scope.row[field.prop]"
        class="icon el-icon-circle-check true"
        title="Tak"
      />
      <i v-else class="icon el-icon-circle-close false" title="Nie" />
    </template>
  </el-table-column>
</template>

<script>
export default {
  props: ['field']
}
</script>

<style lang="scss" scoped>
.true {
  color: #67c23a;
}

.false {
  color: #67c23a;
}
</style>
