import VueRouter from 'vue-router'

import Login from '@/views/Login'
import NotFound from '@/views/404'
import Home from '@/views/Home'

const getView = view => require(`@/views/${view}`).default

let routes = [
  {
    path: '/login',
    component: require('@/views/Login').default,
    name: 'Login',
    hidden: true,
    meta: {
      guests: true
    }
  },
  {
    path: '/404',
    component: require('@/views/404').default,
    name: '404',
    hidden: true,
    meta: {
      guests: true
    }
  },
  {
    path: '/',
    component: require('@/views/Home').default,
    name: 'Home',
    iconCls: 'el-icon-star-on',
    meta: { label: 'Home' },
    redirect: {
      name: 'Dashboard'
    },
    hidden: true
  },
  {
    path: '/dashboard',
    component: require('@/views/Home').default,
    iconCls: 'el-icon-s-home',
    meta: { label: 'Dashboard' },
    children: [
      {
        path: '',
        component: getView('Dashboard/Dashboard'),
        name: 'Dashboard',
        iconCls: 'el-icon-star-on',
        meta: { label: 'Home' }
      }
    ]
  },
  {
    path: '/home',
    component: Home,
    iconCls: 'fa fa-bars',
    meta: { label: 'Strona główna' },
    children: [
      {
        path: 'home-page',
        props: {
          slug: 'strona-glowna'
        },
        component: getView('StaticPages/HomePage/HomePageForm'),
        name: 'Edit HomePage',
        meta: { label: 'Edytuj stronę główną' }
      }
      // {
      //   path: 'videos',
      //   component: getView('StaticPages/HomePage/HomePageVideosList'),
      //   name: 'HomePageVideos List',
      //   meta: { label: 'Lista video' }
      // }
    ]
  },
  {
    path: '/pages',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'Podstrony' },
    children: [
      {
        path: '',
        component: getView('Pages/PagesList'),
        name: 'Pages List',
        meta: { label: 'Lista stron' }
      },
      {
        path: 'add',
        component: getView('Pages/PageForm'),
        name: 'Add Page',
        meta: { label: 'Dodaj stronę' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Pages/PageForm'),
        name: 'Edit Page',
        hidden: true,
        meta: { label: 'Edytuj stronę' }
      }
    ]
  },
  {
    path: '/predefined-pages',
    component: Home,
    iconCls: 'fa fa-bars',
    meta: { label: 'Strony predefiniowane' },
    children: [
      {
        path: 'slider',
        props: {
          slug: 'z-jakiego-powodu-tutaj-jestes'
        },
        component: getView('StaticPages/SliderPage/SliderPageForm'),
        name: 'Edit SliderPage',
        meta: { label: 'Suwak' }
      },
      {
        path: 'suicide-myth-page',
        props: {
          slug: 'mity-na-temat-samobojstw'
        },
        component: getView('StaticPages/SuicideMythsPage/SuicideMythsPageForm'),
        name: 'Edit SuicideMythPage',
        meta: { label: 'Mity na temat samobójstw' }
      },
      {
        path: 'brought-back-to-life-page',
        props: {
          slug: 'przywroceni-zyciu'
        },
        component: getView(
          'StaticPages/BroughtBackToLifePage/BroughtBackToLifePageForm'
        ),
        name: 'Edit BroughtBackToLifePage',
        meta: { label: 'Przywróceni życiu' }
      },
      {
        path: 'free-aid-stations',
        props: {
          slug: 'bezplatne-miejsca-pomocowe'
        },
        component: getView(
          'StaticPages/FreeAidStationsPage/FreeAidStationsPageForm'
        ),
        name: 'Edit FreeAidStationsPage',
        meta: { label: 'Bezpłatne miejsca pomocowe' }
      },
      {
        path: 'toll-free-helplines',
        props: {
          slug: 'bezplatne-numery-pomocowe'
        },
        component: getView(
          'StaticPages/TollFreeHelplinesPage/TollFreeHelplinesPageForm'
        ),
        name: 'Edit TollFreeHelplinesPage',
        meta: { label: 'Bezpłatne numery pomocowe' }
      }
    ]
  },

  {
    path: '/articles',
    component: Home,
    iconCls: 'fa fa-file-text-o',
    meta: { label: 'Artykuły' },
    children: [
      {
        path: '',
        component: getView('Articles/ArticleList'),
        name: 'Article List',
        meta: { label: 'Lista artykułów' }
      },
      {
        path: 'add',
        component: getView('Articles/ArticleForm'),
        name: 'Add Article',
        meta: { label: 'Dodaj artykuł' }
      },
      {
        path: 'tags',
        component: getView('Articles/ArticleTagList'),
        name: 'ArticleTag List',
        meta: { label: 'Lista tagów' }
      },
      {
        path: 'template',
        component: getView('ArticleTemplate/ArticleTemplateForm'),
        name: 'Edit ArticleTemplate',
        meta: { label: 'Edytuj wzór artykułu' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Articles/ArticleForm'),
        name: 'Edit Article',
        hidden: true,
        meta: { label: 'Edytuj artykuł' }
      }
    ]
  },
  {
    path: '/simple-cast-audio',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'SimpleCast Audio' },
    children: [
      {
        path: '',
        component: getView('SimpleCastAudio/SimpleCastAudioList'),
        name: 'SimpleCastAudio List',
        meta: { label: 'Lista audio - SimpleCast' }
      },
      {
        path: 'import',
        component: getView('SimpleCastAudio/SimpleCastAudioImportForm'),
        name: 'Import SimpleCastAudio',
        hidden: true,
        meta: { label: 'Importuj audio - SimpleCast' }
      },
      {
        path: ':id',
        props: true,
        component: getView('SimpleCastAudio/SimpleCastAudioForm'),
        name: 'Edit SimpleCastAudio',
        hidden: true,
        meta: { label: 'Szczegóły audio - SimpleCast' }
      }
    ]
  },
  {
    path: '/vimeo-video',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'Vimeo Video' },
    children: [
      {
        path: '',
        component: getView('VimeoVideo/VimeoVideoList'),
        name: 'VimeoVideo List',
        meta: { label: 'Lista wideo (vimeo)' }
      },
      {
        path: 'add',
        component: getView('VimeoVideo/VimeoVideoForm'),
        name: 'Add VimeoVideo',
        meta: { label: 'Dodaj wideo (vimeo)' }
      },
      {
        path: ':id',
        props: true,
        component: getView('VimeoVideo/VimeoVideoForm'),
        name: 'Edit VimeoVideo',
        hidden: true,
        meta: { label: 'Edytuj wideo (vimeo)' }
      }
    ]
  },
  {
    path: '/help_place',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'Placówki' },
    children: [
      {
        path: '',
        component: getView('HelpPlace/HelpPlaceList'),
        name: 'HelpPlace List',
        meta: { label: 'Lista placówek' }
      },
      {
        path: 'import',
        component: getView('HelpPlace/HelpPlaceImportForm'),
        name: 'Import HelpPlace',
        hidden: true,
        meta: { label: 'Importuj placówki' }
      },
      {
        path: 'add',
        component: getView('HelpPlace/HelpPlaceForm'),
        name: 'Add HelpPlace',
        meta: { label: 'Dodaj placówkę' }
      },
      {
        path: ':id',
        props: true,
        component: getView('HelpPlace/HelpPlaceForm'),
        name: 'Edit HelpPlace',
        hidden: true,
        meta: { label: 'Edytuj placówkę' }
      }
    ]
  },
  {
    path: '/images',
    component: Home,
    iconCls: 'fa fa-image',
    meta: { label: 'Zdjęcia' },
    children: [
      {
        path: '',
        component: getView('Image/ImageList'),
        name: 'Image List',
        meta: { label: 'Lista zdjęć' }
      },
      {
        path: 'add',
        component: getView('Image/ImageForm'),
        name: 'Add Image',
        meta: { label: 'Dodaj zdjęcie' }
      },
      {
        path: 'catalogs',
        component: getView('Image/CatalogList'),
        name: 'Catalog List',
        meta: { label: 'Lista katalogów' }
      },
      {
        path: 'catalogs/add',
        component: getView('Image/CatalogForm'),
        name: 'Add Catalog',
        meta: { label: 'Dodaj katalog' }
      },
      {
        path: 'catalogs/:id',
        props: true,
        component: getView('Image/CatalogForm'),
        name: 'Edit Catalog',
        hidden: true,
        meta: { label: 'Edytuj katalog' }
      },
      {
        path: ':id',
        props: true,
        component: getView('Image/ImageForm'),
        name: 'Edit Image',
        hidden: true,
        meta: { label: 'Edytuj zdjęcie' }
      }
    ]
  },
  {
    path: '/attachments',
    component: Home,
    iconCls: 'fa fa-download',
    meta: { label: 'Załączniki' },
    children: [
      {
        path: '',
        component: getView('Attachments/AttachmentsList'),
        name: 'Attachments List',
        meta: { label: 'Lista załączników' }
      }
    ]
  },
  {
    path: '/menu',
    component: Home,
    iconCls: 'fa fa-bars',
    meta: { label: 'Menu' },
    children: [
      {
        path: 'menu',
        component: getView('Menus/MenuForm'),
        name: 'Edit Menu',
        meta: { label: 'Edytuj menu główne' }
      },
      {
        path: 'footer-menu',
        component: getView('Menus/FooterMenuForm'),
        name: 'Edit FooterMenu',
        meta: { label: 'Edytuj stopkę' }
      }
    ]
  },
  {
    path: '/users',
    component: Home,
    iconCls: 'fa fa-group',
    meta: { label: 'Użytkownicy' },
    children: [
      {
        path: '',
        component: getView('User/UserList'),
        name: 'User List',
        meta: { label: 'Lista użytkowników' }
      },
      {
        path: 'add',
        component: getView('User/UserForm'),
        name: 'Add User',
        meta: { label: 'Dodaj użytkownika' }
      },
      {
        path: ':id',
        props: true,
        component: getView('User/UserForm'),
        name: 'Edit User',
        hidden: true,
        meta: { label: 'Edytuj użytkownika' }
      }
    ]
  },
  {
    path: '/settings',
    component: Home,
    iconCls: 'fa fa-cog',
    meta: { label: 'Ustawienia' },
    children: [
      {
        path: '',
        component: getView('Settings/SettingsForm'),
        name: 'Settings Form',
        meta: { label: 'Konfiguracja strony' }
      }
    ]
  },
  {
    path: '/zendesk',
    component: Home,
    iconCls: 'fa fa-desktop',
    meta: { label: 'Zendesk' },
    children: [
      {
        path: 'tasks',
        component: getView('ZendeskTask/ZendeskTaskList'),
        name: 'ZendeskTask List',
        meta: { label: 'Zadania zendesk (logi)' }
      },
      {
        path: 'messages_export',
        component: getView('ZendeskTask/ZendeskMessagesExport'),
        name: 'ZendeskTask Messages Export',
        meta: { label: 'Export wiadomości' }
      },
      {
        path: 'tasks/:id',
        component: getView('ZendeskTask/ZendeskTaskDetail'),
        name: 'ZendeskTask Detail',
        meta: { label: 'Zadania zendesk' },
        props: true,
        hidden: true
      }
    ]
  },
  {
    path: '*',
    hidden: true,
    redirect: { path: '/404' }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = window.localStorage.getItem('token')
  if (!to.meta.guests && !isUserLoggedIn) {
    next({ name: 'Login', query: { next: to.path } })
    return
  }
  document.title = to.meta.label
    ? `${to.meta.label} - Panel administracyjny`
    : 'Panel administracyjny'
  next()
})

export default router
