<script>
import { ArticleTag } from '@/api'
import ListMixin from '@/crudBases/list/ListMixin'

export default {
  mixins: [ListMixin],
  data() {
    return {
      verboseName: 'Lista tagów',
      verboseNamePlural: 'Lista tagów',
      listFields: [
        { prop: 'id', label: 'id', width: 80 },
        { prop: 'name', label: 'nazwa' },
        { prop: 'slug', label: 'slug' }
      ]
    }
  },
  methods: {
    actions(scope) {
      return [
        this.getButtonComponent({
          icon: 'el-icon-delete',
          onClick: () => this.handleDelete(scope.$index, scope.row),
          type: 'danger',
          condition: this.removeButtonCondition(scope.row)
        })
      ]
    }
  },
  computed: {
    service() {
      return ArticleTag
    }
  }
}
</script>
